<template>
    <div class="content">
        <div class="intro-banner">
            <img src="../assets/bg.jpg" class="banner-img" alt="banner-image">
            <div class="intro-msg">
                <div class="main-intro-info">
                    <div class="intro-msg-headers">
                        <div class="typewriter">
                            <span class="intro-msg-title"> Hello,</span>
                            <span class="intro-msg-title"> I'm <span class="intro-msg-name">Tofig</span></span>
                        </div>
                    </div>
                    <span class="intro-msg-text">Front-end, UI/UX Developer</span>
                </div>
            </div>
        </div>
        <div class="content-inner">
            <div class="skills-section">
                <div class="content-skills-block">
                    <div class="skill-box" style="color:#ffd60a"><i class="fa-brands fa-js"></i></div>
                    <div class="skill-box" style="color:#8ecae6"><i class="fa-brands fa-react"></i></div>
                    <div class="skill-box" style="color: #42b983;"><i class="fa-brands fa-vuejs"></i></div>
                    <div class="skill-box" style="color: #fb5607;"><i class="fa-brands fa-html5"></i></div>
                    <div class="skill-box" style="color: #2d00f7;"><i class="fa-brands fa-css3-alt"></i></div>
                    <div class="skill-box" style="color: #ff0054;"><i class="fa-brands fa-sass"></i></div>
                    <div class="skill-box" style="color: #7209b7;"><i class="fa-brands fa-bootstrap"></i></div>
                </div>
                <div class="content-skills-table">
                    <div class="skill-cell">
                        <span class="skill-title">
                            JavaScript
                        </span>
                        <div class="bar">
                            <div id="js-bar"></div>
                        </div>
                    </div>
                    <div class="skill-cell">
                        <span class="skill-title">
                            React
                        </span>
                        <div class="bar">
                            <div id="js-bar"></div>
                        </div>
                    </div>
                    <div class="skill-cell">
                        <span class="skill-title">
                            Vue.js
                        </span>
                        <div class="bar">
                            <div id="vue-bar"></div>
                        </div>
                    </div>
                    <div class="skill-cell">
                        <span class="skill-title">
                            Vuex
                        </span>
                        <div class="bar">
                            <div id="vuex-bar"></div>
                        </div>
                    </div>
                    <div class="skill-cell">
                        <span class="skill-title">
                            TypeScript
                        </span>
                        <div class="bar">
                            <div id="typescript-bar"></div>
                        </div>
                    </div>
                    <div class="skill-cell">
                        <span class="skill-title">
                            HTML
                        </span>
                        <div class="bar">
                            <div id="html-bar"></div>
                        </div>
                    </div>
                    <div class="skill-cell">
                        <span class="skill-title">
                            CSS
                        </span>
                        <div class="bar">
                            <div id="css-bar"></div>
                        </div>
                    </div>
                    <div class="skill-cell">
                        <span class="skill-title">
                            Sass
                        </span>
                        <div class="bar">
                            <div id="scss-bar"></div>
                        </div>
                    </div>
                    <div class="skill-cell">
                        <span class="skill-title">
                            jQuery
                        </span>
                        <div class="bar">
                            <div id="jQuery-bar"></div>
                        </div>
                    </div>
                    <div class="skill-cell">
                        <span class="skill-title">
                            Bootstrap
                        </span>
                        <div class="bar">
                            <div id="bootstrap-bar"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-experience-block">
                <div class="commercial-exp-block">
                    <div class="section-title">
                        <span class="section-title-text">Commercial Experience</span>
                    </div>
                    <div class="comm-exp">
                        <div class="comm-exp-image">
                            <img src="../assets/job-logo.png" alt="" class="job-logo">
                        </div>
                        <div class="comm-exp-desc">
                            <div class="job-title-dates">
                                <span class="comm-exp-title-text">StoneX Poland sp z o.o.</span>
                                <span class="comm-exp-dates">May 2022 – Oct 2022</span>
                            </div>
                            <div class="comm-exp-position">
                                <span class="comm-exp-position-text">ServiceNow developer - Intern</span>
                            </div>
                            <div class="comm-exp-info">
                                <span class="comm-exp-overall">
                                    <ul class="exp-list">
                                        <li class="list-item">
                                            Developed and tested ServiceNow platform based applications.
                                        </li>
                                        <li class="list-item">Collaborated with the ServiceNow team on incidents and
                                            stories.
                                        </li>
                                        <li class="list-item">
                                            Worked in an Agile environment on the Scrum based project with weekly stand-ups.
                                        </li>
                                        <li class="list-item">
                                            Involved in daily standups, including Story Pointing, Sprint, Story and Incident
                                            Reviews.
                                        </li>
                                        <li class="list-item">
                                            Successfully completed and passed ITIL courses offered on the Now Learning
                                            platform.
                                        </li>
                                        <li class="list-item">
                                            Engaged directly with the stakeholders, providing assistance and collaborating
                                            on solutions for the given incidents.
                                        </li>
                                    </ul>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="comm-exp">
                        <div class="comm-exp-image">
                            <img src="../assets/azal-logo.png" alt="" class="job-logo">
                        </div>
                        <div class="comm-exp-desc">
                            <div class="job-title-dates">
                                <span class="comm-exp-title-text">Azerbaijan Airlines</span>
                                <span class="comm-exp-dates">Jan 2014 – Nov 2019</span>
                            </div>
                            <div class="comm-exp-position">
                                <span class="comm-exp-position-text">Flight Attendant</span>
                            </div>
                            <div class="comm-exp-info">
                                <span class="comm-exp-overall">
                                    <ul class="exp-list">
                                        <li class="list-item">Completed over 4,500 hours of international and domestic
                                            flights within Boeing
                                            and Airbus commercial aircrafts.
                                        </li>
                                        <li class="list-item">Implemented domestic and international commercial flights.
                                        </li>
                                        <li class="list-item">Conducted pre-flight safety check of cabin emergency
                                            equipment. Assisted during
                                            onboard emergencies by providing CPR and basic first aid.
                                        </li>
                                    </ul>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="certificates">
                    <div class="section-title">
                        <button class="section-extend-btn" @click="certCollapser()">
                            <span class="section-title-text">Certificates</span>
                            <span class="material-symbols-outlined" v-if="!display_certs">expand_more</span>
                            <span class="material-symbols-outlined" v-else>expand_less</span>
                        </button>
                    </div>
                    <div class="certificates-block" v-if="display_certs">
                        <div class="certificate-card">
                            <div class="cert-image">
                                <img src="../assets/fcc-logo.jpg" alt="" class="cert-img">
                            </div>
                            <div class="cert-info">
                                <div class="cert-title">Responsive Web Design</div>
                                <div class="cert-title-brand">freeCodeCamp</div>
                                <a target=”_blank”
                                    href="https://www.freecodecamp.org/certification/tofik/responsive-web-design"
                                    class="cert-link">
                                    <i class="fa-solid fa-earth-americas cert-link-icon"></i>
                                    <span class="cert-link-title">View certificate</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <span class="start-point" id="experienceBlock"></span>
                </div>
            </div>
        </div>
        <button class="scroll-to-top" @click="scrollToTop">
            <i class="fa-solid fa-angle-up"></i>
        </button>
    </div>
</template>
  
<script>
export default {
    name: 'home',
    data() {
        return {
            display_certs: false,
        }
    },
    methods: {
        certCollapser() {
            this.display_certs = !this.display_certs;
        },
        handleScroll() {
            const scrollToTopBtn = document.querySelector('.scroll-to-top');
            if (scrollToTopBtn) {
                scrollToTopBtn.style.display = (window.scrollY > 200) ? 'block' : 'none';
            }
        },
        scrollToTop() {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    },
    mounted() {
        this.scrollToTop();
        window.addEventListener('scroll', this.handleScroll);
    },
}
</script>
  
  
<style scoped>
/* Typewriter effect */

.typewriter {
    font-family: monospace;
    overflow: hidden;
    border-right: 3px solid #ced4da;
    white-space: nowrap;
    margin: 0 auto;
    animation:
        typing 3s steps(30, end),
        blink-caret 1.5s step-end infinite;
}

@keyframes typing {
    from {
        width: 0
    }

    to {
        width: 100%
    }
}

@keyframes blink-caret {

    from,
    to {
        border-color: transparent
    }

    50% {
        border-color: #ced4da
    }
}

/*  */

/* Scroll to the top buttton */
.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: none;
    align-items: center;
    justify-content: center;
    background-color: #202020;
    color: #ced4da;
    border: 1px solid #343a34;
    outline: none;
    cursor: pointer;
    font-size: 20px;
    padding: .7rem;
    border-radius: 5px;
    transition: 200ms ease-in-out;
}

/* Scroll to the top buttton */

.content-inner {
    margin: 0 auto;
    max-width: 100%;
}


.intro-banner {
    height: 100vh;
    position: relative;
}

.banner-img {
    height: 100%;
    width: 100%;
    object-fit: fill;
}

.intro-msg {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, .6);
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    color: #ced4da;
}

.main-intro-info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.intro-msg-headers {
    margin-bottom: .5rem;
}

.intro-msg-title {
    font-size: 36px;
    font-weight: 800;
    color: #ced4da;
}

.intro-msg-name {
    font-size: 36px;
    font-weight: 800;
    color: #42b983;
}

.intro-msg-text {
    width: 100%;
    font-size: 30px;
    font-weight: 500;
    color: #ced4da;
}

/* Flicker effect */




/* ///////////////////////////////// */

@keyframes letter-flicker {
    0% {
        opacity: 0.1;
    }

    2% {
        opacity: 0.1;
    }

    4% {
        opacity: 1;
    }


    19% {
        opacity: 1;
    }

    21% {
        opacity: 0.1;
    }

    23% {
        opacity: 1;
    }

    80% {
        opacity: 1;
    }

    83% {
        opacity: 0.4;
    }

    87% {
        opacity: 1;
    }
}

/*  */

.skills-section {
    background-image: url('../assets/skills-bg.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    border-top: 3px solid #101010;
}

.content-skills-block {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;
    padding: 1rem;
    background-color: rgba(0, 0, 0, .9);
}

.skill-box {
    font-size: 75px;
    -webkit-filter: drop-shadow(0px 0px 25px #003566);
    filter: drop-shadow(0px 0px 25px #003566);
    /* animation: letter-flicker 2s linear infinite; */
}

.section-title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 150px;
    border-top: 2px solid #101010;
    border-bottom: 2px solid #101010;
    background-color: rgba(0, 0, 0, .8);
}

.section-title-text {
    font-size: 22px;
    font-weight: 700;
    margin-right: .5rem;
    color: #cad2c5;
}

.section-extend-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .5rem;
    border: none;
    background: none;
    cursor: pointer;
    color: #adb5bd;
    width: 100%;
    height: 100%;
}

.content-skills-table {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    padding: 1rem;
}

.skill-cell {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 1rem;
    border: 1px solid #000;
    border-radius: 5px;
    background: #101010;
    box-shadow: 0px 0px 25px #000;
}

.skill-title {
    font-size: 18px;
    font-weight: 500;
    color: #ced4da;
    margin-bottom: .5rem;
}

.bar {
    position: relative;
    border: 1px solid #42b983;
    border-radius: 5px;
    width: 100%;
}

.bar div {
    position: relative;
    width: 0;
    height: 9px;
    border-radius: 5px;
    background-image: linear-gradient(135deg, #667eea 10%, #764ba2 100%);
}

#js-bar {
    animation: js-fill 4s forwards;
}

@keyframes js-fill {
    100% {
        width: 98%;
    }
}

#vue-bar {
    animation: vue-fill 4s forwards;
}

@keyframes vue-fill {
    100% {
        width: 85%;
    }
}

#vuex-bar {
    animation: vuex-fill 4s forwards;
}

@keyframes vuex-fill {
    100% {
        width: 75%;
    }
}

#typescript-bar {
    animation: typescript-fill 4s forwards;
}

@keyframes typescript-fill {
    100% {
        width: 85%;
    }
}

#jQuery-bar {
    animation: jQuery-fill 4s forwards;
}

@keyframes jQuery-fill {
    100% {
        width: 85%;
    }
}

#bootstrap-bar {
    animation: bootstrap-fill 4s forwards;
}

@keyframes bootstrap-fill {
    100% {
        width: 85%;
    }
}

#html-bar {
    animation: html-fill 4s forwards;
}

@keyframes html-fill {
    100% {
        width: 100%;
    }
}

#css-bar {
    animation: css-fill 4s forwards;
}

@keyframes css-fill {
    100% {
        width: 100%;
    }
}

#scss-bar {
    animation: scss-fill 4s forwards;
}

@keyframes scss-fill {
    100% {
        width: 95%;
    }
}



.content-experience-block {
    display: grid;
    grid-template-columns: 1fr;
}

.commercial-exp-block {
    display: grid;
    grid-template-columns: 1fr;
    background-image: url('../assets/experience-bg.jpg');
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.comm-exp {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(6, 1fr);
    gap: 10px;
    padding: 1.5rem;
    border-top: 2px solid #003566;
    background-color: rgba(0, 0, 0, .4);
}

.comm-exp-image {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column: 1/4;
    border-radius: 5px;
    height: 100%;
}

.job-logo {
    height: 145px;
    width: 250px;
    padding: 1.5rem;
    border-radius: 5px;
    object-fit: contain;
    background: #ced4da;
    -webkit-filter: drop-shadow(0px 0px 15px #003566);
    filter: drop-shadow(0px 0px 15px #003566);
}

.job-title-dates {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: .5rem;
}

.comm-exp-title-text {
    font-size: 18px;
    font-weight: 700;
    padding: .5rem;
    border-radius: 5px;
    color: #ced4da;
    background: #202020;
}

.comm-exp-dates {
    font-size: 18px;
    font-weight: 700;
    padding: .5rem;
    border-radius: 5px;
    color: #ced4da;
    background: #202020;
}

.comm-exp-position {
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-bottom: .5rem;
}

.comm-exp-position-text {
    font-size: 16px;
    font-weight: 700;
    color: #42b983;
}

.comm-exp-desc {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    grid-column: 4/7;
    border-radius: 5px;
    padding: 1.5rem;
    height: 100%;
    background-color: rgba(0, 0, 0, .9);
}

.comm-exp-info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.comm-exp-overall {
    padding: 1rem;
    color: #adb5bd;
    text-align: justify;
}

.side-exp {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 1rem;
    background-color: rgba(0, 0, 0, .5);
}

.certificates {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-image: url('../assets/cert-bg.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
}

.certificates-block {
    display: flex;
    align-items: center;
    padding: 1rem;
}

.certificate-card {
    display: flex;
    align-items: center;
    padding: 1rem;
    border-radius: 5px;
    background: #101010;
}

.cert-image {
    min-width: 150px;
    max-width: 200px;
    text-align: center;
    height: 135px;
    border-radius: 5px;
    box-shadow: 0px 0px 15px #000;
    margin-right: 1rem;
}

.cert-img {
    height: 100%;
    width: 100%;
    border-radius: 5px;
    object-fit: fill;
}

.cert-info {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.cert-title {
    text-align: start;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: .5rem;
    color: #667eea;
}

.cert-title-brand {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: .5rem;
    color: #adb5bd;
}

.cert-link {
    border: 1px solid #343a34;
    padding: .5rem;
    border-radius: 5px;
    text-decoration: none;
    color: #adb5bd;
    transition: 200ms ease-in-out;
}

.cert-link:hover {
    background: #00509d;
    transition: 200ms ease-in-out;
}

.cert-link-title {
    font-size: 16px;
    font-weight: 700;
}

.cert-link-icon {
    margin-right: .5rem;
}



@media(max-width: 768px) {
    .banner-img {
        object-fit: cover;
    }

    .intro-msg-title,
    .intro-msg-name {
        font-size: 25px;
    }

    .intro-msg-text {
        font-size: 20px;
    }

    .content-skills-block {
        grid-template-columns: repeat(3, 1fr);
    }

    .skill-box {
        font-size: 45px;
    }

    .content-skills-table {
        display: grid;
        grid-template-columns: 1fr;
        gap: 5px;
        padding: .5rem;
    }

    .commercial-exp-block {
        background-image: url('../assets/experience-bg-mobile.jpg');
        background-size: cover;
    }

    .comm-exp {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr;
        gap: 5px;
        padding: .5rem;
    }

    .comm-exp-image {
        display: flex;
        align-items: center;
        justify-content: center;
        grid-column: 1;
    }

    .comm-exp-desc {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 1rem;
        grid-column: 1;
    }

    .comm-exp-title-text {
        margin-right: .5rem;
        font-size: 14px;
    }

    .comm-exp-dates {
        font-size: 14px;
    }

    .certificates-block {
        grid-template-columns: 1fr;
    }

    .cert-image {
        min-width: 100px;
        max-width: 100px;
        text-align: center;
        height: 100px;
        border-radius: 5px;
        box-shadow: 0px 0px 15px #000;
        margin-right: 1rem;
    }

    .cert-info {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .cert-link-title {
        font-size: 12px;
        font-weight: 500;
    }
}
</style>