<template>
    <footer class="footer">
        <div class="footer-inner">
            <div class="footer-links">
                <a href="mailto:tofig.valiyev1993@gmail.com" class="external-link">
                    <span class="external-link-email">
                        <i class="fa-solid fa-envelope"></i>
                    </span>
                </a>
                <a href="https://www.linkedin.com/in/tofig-valiyev" class="external-link">
                    <span class="external-link-linkedin">
                        <i class="fa-brands fa-linkedin"></i>
                    </span>
                </a>
                <a href="https://github.com/tofa93" class="external-link">
                    <span class="external-link-github">
                        <i class="fa-brands fa-square-github"></i>
                    </span>
                </a>
            </div>
            <hr>
            <div class="copyrights">
                <span class="copyright-date">
                    <i class="fa-regular fa-copyright"></i>
                    {{ new Date().getFullYear() }}</span>
                <span class="span copyright-host">Tofig.</span>
                <span class="span copyright-info">All rights reserved.</span>
            </div>
        </div>
    </footer>
</template>

<style>
hr {
    display: block;
    border-style: inset;
    border-width: 1px;
    width: 100%;
    color: #151515;
}

.footer {
    background-image: url('../assets/footer-bg.png');
    width: 100%;
    height: 250px;
    border-top: 2px solid #101010;
}

.footer-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    padding: 2rem;
}

.footer-links {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: .5rem;
    font-size: 18px;
    font-weight: 500;
}

.external-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    margin: 1rem;
    color: #ced4da;
    transition: 200ms ease-in-out;
}

.external-link:hover {
    color: #adb5bd;
    transition: 200ms ease-in-out;
}

.external-link-email {
    font-size: 35px;
    color: #42b983;
    transition: 200ms ease-in-out;
}

.external-link-email:hover {
    color: #2c6e49;
    transition: 200ms ease-in-out;
}

.external-link-linkedin {
    font-size: 35px;
    color: #00509d;
    transition: 200ms ease-in-out;
}

.external-link-linkedin:hover {
    color: #003f88;
    transition: 200ms ease-in-out;
}

.external-link-github {
    font-size: 35px;
    color: #adb5bd;
    text-decoration: none;
}

.external-link-github:hover {
    color: #6c757d;
    transition: 200ms ease-in-out;
}

.copyrights {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 2rem;
    /* background-color: rgba(0, 0, 0, .9); */
}

.copyright-date {
    color: #ced4da;
    margin-right: .3rem;
}

.copyright-host {
    color: #ced4da;
    margin-right: .5rem;
}

.copyright-info {
    color: #ced4da;
}
</style>