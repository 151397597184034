<template>
  <Navbar />
  <div class="container">
    <router-view :key="$route.path" />
  </div>
  <Footer></Footer>
</template>
<script>

import Navbar from './components/Navbar.vue'
import Footer from './components/Footer.vue'

export default {
  components: {
    Navbar,
    Footer,
  }
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-family: 'Roboto', sans-serif;
  margin: 0 auto;
  scroll-behavior: smooth;
}

body {
  margin: 0 auto;
  background: #000;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #343a40;
}

::-webkit-scrollbar-thumb {
  background: #adb5bd;
}

::-webkit-scrollbar-thumb:hover {
  background: #dee2e6;
}
</style>
