<template>
    <nav class="header">
        <div class="header-links">
            <router-link to="/" class="header-link">Home</router-link>
            <router-link to="/projects" class="header-link">Projects</router-link>
        </div>
    </nav>
</template>

<script>
export default {

}

</script>

<style scoped>
.header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 55px;
    position: absolute;
    z-index: 1;
    top: 0;
    padding: 0 8rem;
    width: 100%;
    background: #151515;
    box-shadow: 0 2px 10px 0 rgb(0, 0, 0, .4);
}

.header-links {
    display: flex;
    align-items: center;
}

.header-link {
    display: inline-block;
    position: relative;
    text-decoration: none;
    font-size: 18px;
    font-weight: bold;
    color: #adb5bd;
    margin: 1rem;
}

.header-link.router-link-exact-active {
    color: #42b983;
}

.header-link::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #0087ca;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.header-link:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}
</style>