<template>
    <div class="content">
        <div class="intro-banner">
            <img src="../assets//project-bg.jpg" class="banner-img" alt="banner-image">
            <div class="intro-msg">
                <div class="main-intro-info">
                    <div class="intro-msg-headers">
                        <div class="typewriter">
                            <span class="intro-msg-title">My<span class="intro-msg-project"> Projects</span></span>
                        </div>
                    </div>
                    <!-- <span class="intro-msg-text">All source codes are available on my Github public repository</span> -->
                    <a href="#projects" class="intro-msg-btn">Start</a>
                </div>
            </div>
        </div>
        <div class="content-inner">
            <div class="content-projects-area" id="projects">
                <div class="projects-title">Main projects</div>
                <div class="large-projects">
                    <div class="large-project">
                        <div class="project-demo-screen">
                            <div class="demo-screen-image">
                                <img src="../assets/newslab-image.png" alt="demo-screen" class="demo-screen-img">
                            </div>
                        </div>
                        <div class="project-information-links">
                            <div class="project-title">
                                <span class="project-title-text">NewsLab</span>
                            </div>
                            <div class="project-desc">
                                The website is a platform designed to retrieve news from the NewsAPI and offers
                                users the ability to contribute their own news articles, posts, and media content. It
                                provides a comprehensive and up-to-date collection of news from various sources, ensuring
                                users stay informed on current events.
                                Users have the option to post their own news articles and posts on the website. They can
                                write articles, add images, videos, and other media elements to enhance their content.
                                Also the website offers an online marketplace where users can buy and
                                sell items. Users can create listings, upload product images, provide descriptions, and set
                                prices.
                            </div>
                            <div class="project-tools">
                                <span class="tool-lang" id="js-logo"><i class="fa-brands fa-js"></i></span>
                                <span class="tool-lang" id="react-logo"><i class="fa-brands fa-react"></i></span>
                                <span class="tool-lang" id="html-logo"><i class="fa-brands fa-html5"></i></span>
                                <span class="tool-lang" id="css-logo"><i class="fa-brands fa-css3-alt"></i></span>
                            </div>
                            <div class="project-links">
                                <!-- <a target=”_blank” href="#" class="web-link">
                                    <i class="fa-solid fa-earth-americas"></i>
                                    Demo</a> -->
                                <a target=”_blank” href="https://github.com/tofa93/Newslab" class="github-repo-link">
                                    <i class="fa-brands fa-github"></i>
                                    Github</a>
                            </div>
                        </div>
                    </div>
                    <div class="large-project">
                        <div class="project-demo-screen">
                            <div class="demo-screen-image">
                                <img src="../assets/arc-books-image.png" alt="demo-screen" class="demo-screen-img">
                            </div>
                        </div>
                        <div class="project-information-links">
                            <div class="project-title">
                                <span class="project-title-text">Arc-Books</span>
                            </div>
                            <div class="project-desc">Arc-Books is an innovative website that utilizes the power of Google
                                API to
                                provide users with an immersive and personalized reading experience. With Arc-Books, users
                                can search and discover a vast collection of books from various genres, read
                                them online and share their thoughts with a community of readers.
                                The platform also offers a convenient feature that allows users to save books
                                for later reading.
                            </div>
                            <div class="project-tools">
                                <span class="tool-lang" id="js-logo"><i class="fa-brands fa-js"></i></span>
                                <span class="tool-lang" id="react-logo"><i class="fa-brands fa-react"></i></span>
                                <span class="tool-lang" id="html-logo"><i class="fa-brands fa-html5"></i></span>
                                <span class="tool-lang" id="css-logo"><i class="fa-brands fa-css3-alt"></i></span>
                            </div>
                            <div class="project-links">
                                <!-- <a target=”_blank” href="#" class="web-link">
                                    <i class="fa-solid fa-earth-americas"></i>
                                    Demo</a> -->
                                <a target=”_blank” href="https://github.com/tofa93/Arc-Books" class="github-repo-link">
                                    <i class="fa-brands fa-github"></i>
                                    Github</a>
                            </div>
                        </div>
                    </div>
                    <div class="large-project">
                        <div class="project-demo-screen">
                            <div class="demo-screen-image">
                                <img src="../assets/corner-shop-image.png" alt="demo-screen" class="demo-screen-img">
                            </div>
                        </div>
                        <div class="project-information-links">
                            <div class="project-title">
                                <span class="project-title-text">Corner Shop</span>
                            </div>
                            <div class="project-desc">
                                Corner Shop is a e-commerce website is a convenient online platform that offers a wide range
                                of
                                products including snacks, alcohol, tobacco, and fast food. It aims to provide customers
                                with a seamless shopping experience. The website features a user-friendly
                                interface with a comprehensive catalog of products, making it easy for users to find and
                                select their desired items.
                                One of the key functionalities of the website is the shopping cart feature. Customers can
                                add products to their cart while browsing, allowing them to collect multiple items before
                                proceeding to checkout. The shopping cart provides a summary of the selected items,
                                including quantities and prices, giving customers an overview of their purchase.
                            </div>
                            <div class="project-tools">
                                <span class="tool-lang" id="js-logo"><i class="fa-brands fa-js"></i></span>
                                <span class="tool-lang" id="vue-logo"><i class="fa-brands fa-vuejs"></i></span>
                                <span class="tool-lang" id="html-logo"><i class="fa-brands fa-html5"></i></span>
                                <span class="tool-lang" id="css-logo"><i class="fa-brands fa-css3-alt"></i></span>
                            </div>
                            <div class="project-links">
                                <!-- <a target=”_blank” href="#" class="web-link">
                                    <i class="fa-solid fa-earth-americas"></i>
                                    Demo</a> -->
                                <a target=”_blank” href="https://github.com/tofa93/CornerShop" class="github-repo-link">
                                    <i class="fa-brands fa-github"></i>
                                    Github</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="projects-title">Side projects</div>
                <div class="small-projects">
                    <div class="carousel" ref="carousel">
                        <div class="small-project">
                            <div class="sm-project-image">
                                <img src="../assets/arcbooks.png" alt="" class="sm-project-img">
                            </div>
                            <div class="sm-project-panel">
                                <div class="sm-project-title">Blog maker</div>
                                <div class="sm-project-link">
                                    <a target=”_blank” href="https://github.com/tofa93/React-blogs" class="sm-github-link">
                                        <i class="fa-brands fa-github"></i>
                                        Github</a>
                                </div>
                            </div>
                        </div>
                        <div class="small-project">
                            <div class="sm-project-image">
                                <img src="../assets/ip-finer-img.png" alt="" class="sm-project-img">
                            </div>
                            <div class="sm-project-panel">
                                <div class="sm-project-title">IP Address finder</div>
                                <div class="sm-project-link">
                                    <a target=”_blank” href="https://github.com/tofa93/IP-Address-Finder"
                                        class="sm-github-link">
                                        <i class="fa-brands fa-github"></i>
                                        Github</a>
                                </div>
                            </div>
                        </div>
                        <div class="small-project">
                            <div class="sm-project-image">
                                <img src="../assets/movie-app-img.jpg" alt="" class="sm-project-img">
                            </div>
                            <div class="sm-project-panel">
                                <div class="sm-project-title">TMDB Movie App</div>
                                <div class="sm-project-link">
                                    <a target=”_blank” href="https://github.com/tofa93/Movie-App" class="sm-github-link">
                                        <i class="fa-brands fa-github"></i>
                                        Github</a>
                                </div>
                            </div>
                        </div>
                        <div class="small-project">
                            <div class="sm-project-image">
                                <img src="../assets\/backup-reader-img.jpg" alt="" class="sm-project-img">
                            </div>
                            <div class="sm-project-panel">
                                <div class="sm-project-title">Telegram Backup reader</div>
                                <div class="sm-project-link">
                                    <a target=”_blank” href="https://github.com/tofa93/Telegram-message-reader"
                                        class="sm-github-link">
                                        <i class="fa-brands fa-github"></i>
                                        Github</a>
                                </div>
                            </div>
                        </div>
                        <div class="small-project">
                            <div class="sm-project-image">
                                <img src="../assets/bartender-img.png" alt="" class="sm-project-img">
                            </div>
                            <div class="sm-project-panel">
                                <div class="sm-project-title">Bartender App</div>
                                <div class="sm-project-link">
                                    <a target=”_blank” href="https://github.com/tofa93/Brotender-App"
                                        class="sm-github-link">
                                        <i class="fa-brands fa-github"></i>
                                        Github</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button class="scroll-to-top" @click="scrollToTop">
            <i class="fa-solid fa-angle-up"></i>
        </button>
    </div>
</template>
  
<script>
import Flickity from 'flickity';
import imagesLoaded from 'imagesloaded';

export default {
    name: 'projects',
    methods: {
        scrollToTop() {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        handleScroll() {
            const scrollToTopBtn = document.querySelector('.scroll-to-top');
            if (scrollToTopBtn) {
                scrollToTopBtn.style.display = (window.scrollY > 200) ? 'block' : 'none';
            }
        }
    },
    mounted() {
        this.scrollToTop();
        window.addEventListener('scroll', this.handleScroll);
        this.flkty = new Flickity(this.$refs.carousel, {
            pageDots: false,
            draggable: true
        });
        imagesLoaded(this.$refs.carousel, () => {
            this.flkty.resize();
        });
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    },
}
</script>
<style scoped>
@import '~flickity/dist/flickity.css';

.typewriter {
    font-family: monospace;
    overflow: hidden;
    border-right: 3px solid #ced4da;
    white-space: nowrap;
    margin: 0 auto;
    letter-spacing: .15em;
    animation:
        typing 2s steps(30, end),
        blink-caret 1.5s step-end infinite;
}

@keyframes typing {
    from {
        width: 0
    }

    to {
        width: 100%
    }
}

@keyframes blink-caret {

    from,
    to {
        border-color: transparent
    }

    50% {
        border-color: #ced4da
    }
}

/* Scroll to the top buttton */
.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: none;
    align-items: center;
    justify-content: center;
    background-color: #202020;
    color: #ced4da;
    border: 1px solid #343a34;
    outline: none;
    cursor: pointer;
    font-size: 20px;
    padding: .7rem;
    border-radius: 5px;
    transition: 200ms ease-in-out;
}

/* Scroll to the top buttton */

.content-inner {
    margin: 0 auto;
    max-width: 100%;
}

.intro-banner {
    height: 100vh;
    position: relative;
}

.banner-img {
    height: 100%;
    width: 100%;
    object-fit: fill;
}

.intro-msg {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, .1);
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    color: #ced4da;
}

.main-intro-info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.intro-msg-headers {
    margin-bottom: 2.5rem;
}

.intro-msg-title {
    font-size: 35px;
    font-weight: 800;
    color: #adb5bd;
}

.intro-msg-project {
    font-size: 36px;
    font-weight: 800;
    color: #ffd60a;
}

.intro-msg-btn {
    text-decoration: none;
    outline: 0;
    width: 100%;
    padding: .7rem 1.5rem .7rem 1.5rem;
    border-radius: 25px;
    font-size: 20px;
    font-weight: 600;
    background: none;
    text-align: center;
    cursor: pointer;
    color: #ced4da;
    border: 2px solid #00509d;
    box-shadow: 0px 0px 10px #00509d;
    backdrop-filter: blur(5px) brightness(75%) saturate(70%);
    transition: 200ms ease-in-out;
}

.intro-msg-btn:hover {
    border: 2px solid #42b983;
    box-shadow: 0px 0px 10px #42b983;
    transition: 200ms ease-in-out;
}

.content-skills-block {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(6, 1fr);
    gap: 5px;
    padding: .5rem;
    border-top: 2px solid #202020;
    background: #151515;
}

.skill-box {
    font-size: 75px;
}

.content-projects-area {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr;
}

.projects-title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 1mm;
    height: 50px;
    padding: 1rem;
    color: #cad2c5;
    background: #101010;
    box-shadow: 0 2px 10px 0 rgb(0, 0, 0, .4);
}

/* large projects */

.large-projects {
    display: grid;
    grid-template-columns: 1fr;
    gap: 5px;
    background-image: url('../assets/large-projects-bg.jpg');
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.large-project {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    box-shadow: 0px 0px 5px #000;
    background-color: rgba(0, 0, 0, .5);
}

.project-demo-screen {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column: 1/2;
    border-radius: 5px;
}

.demo-screen-image {
    margin: 0;
    padding: 0;
}

.demo-screen-img {
    margin: 0;
    padding: 0;
    height: 550px;
    width: 550px;
    border-radius: 5px;
    -webkit-filter: drop-shadow(0px 0px 25px #003566);
    filter: drop-shadow(0px 0px 25px #003566);
}

.project-information-links {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 1rem;
    border-radius: 5px;
    grid-column: 2/5;
    background-color: rgba(0, 0, 0, .7);
}

.project-title {
    width: 100%;
    padding: .5rem;
    margin-bottom: 1rem;
}

.project-title-text {
    font-size: 24px;
    font-weight: 800;
    color: #cad2c5;
}

.project-desc {
    text-align: justify;
    font-size: 16px;
    font-weight: 500;
    padding: .5rem;
    color: #cad2c5;
}

.project-tools {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 1rem;
}

.tool-lang {
    cursor: pointer;
    margin: .5rem;
    font-size: 45px;
    transition: 200ms ease-in-out;
}

#js-logo {
    color: #ffd60a;
}

#react-logo {
    color: #8ecae6;
}

#vue-logo {
    color: #42b983;
}

#html-logo {
    color: #fb5607;
}

#css-logo {
    color: #2d00f7;
}

.project-links {
    width: 100%;
    padding: .5rem;
}

.web-link {
    font-size: 20px;
    font-weight: 500;
    text-decoration: none;
    border: 1px solid #444;
    border-radius: 5px;
    padding: .5rem 1rem .5rem 1rem;
    margin: 5px;
    color: #ced4da;
    background-color: rgba(0, 0, 0, .7);
    transition: 200ms ease-in-out;
}

.web-link:hover {
    background: #003566;
    transition: 200ms ease-in-out;
}

.github-repo-link {
    font-size: 20px;
    font-weight: 500;
    text-decoration: none;
    border: 1px solid #444;
    border-radius: 5px;
    padding: .5rem 1rem .5rem 1rem;
    margin: 5px;
    color: #ced4da;
    background-color: rgba(0, 0, 0, .7);
    transition: 200ms ease-in-out;
}

.github-repo-link:hover {
    background: #212529;
    transition: 200ms ease-in-out;
}

/* small projects */

.small-projects {
    background: #151515;
}

.carousel {
    background-image: url('../assets/experience-bg.jpg');
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 5px;
}

.small-project {
    padding: .3rem;
    margin: .5rem;
    background: #151515;
    border: 1px solid #202020;
    border-radius: 5px;
    box-shadow: 0px 0px 15px #000;
}

.sm-project-image {
    height: 200px;
    width: 235px;
    margin-bottom: .3rem;
}

.sm-project-img {
    border-radius: 5px;
    height: 100%;
    width: 100%;
}

.sm-project-panel {
    display: flex;
    align-items: center;
    flex-direction: column;
    border: 1px solid #343a34;
    background: #101010;
    border-radius: 5px;
    padding: .3rem;
}

.sm-project-title {
    width: 100%;
    margin-bottom: .3rem;
    color: #ced4da;
    padding: .5rem;
    border-radius: 5px;
}

.sm-project-link {
    display: flex;
    width: 100%;
}

.sm-github-link {
    width: 100%;
    text-decoration: none;
    padding: .5rem;
    font-size: 18px;
    font-weight: 500;
    color: #ced4da;
    background: #212529;
    border: 1px solid #343a34;
    border-radius: 5px;
    transition: 200ms ease-in-out;
}

.sm-github-link:hover {
    background: #003566;
    transition: 200ms ease-in-out;
}

@media(max-width: 768px) {
    .banner-img {
        object-fit: cover;
    }

    .intro-msg-title,
    .intro-msg-project {
        font-size: 25px;
    }

    .content-skills-block {
        grid-template-columns: repeat(4, 1fr);
    }

    .content-skills-block {
        grid-template-columns: repeat(5, 1fr);
    }

    .large-project {
        grid-template-columns: 1fr;
        gap: 5px;
        padding: .5rem;
    }

    .project-demo-screen {
        grid-column: 1;
    }

    .demo-screen-img {
        height: 350px;
        width: 300px;
    }

    .project-information-links {
        grid-column: 1;
    }

    .sm-project-image {
        height: 150px;
        width: 200px;
        margin-bottom: .3rem;
    }
}
</style>
  